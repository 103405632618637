.gallery {
  padding-top: 25px;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  &--custom {
    @include response(1023px) {
      flex-direction: column;
      align-items: center;
      .gallery__sidebar {
        max-width: 100%;
        flex-basis: auto;
        margin: 0 auto 20px;
      }
    }
  }
  &--margin-mobile {
    @include response(1250px) {
      margin: 0 auto 80px;
    }
  }
  @include response(1250px) {
    padding: 25px 15px 0;
  }
  @include response(767px) {
    flex-direction: column;
    align-items: center;
  }
  &__sidebar {
    max-width: 186px;
    width: 100%;
    flex-basis: 186px;
    flex-shrink: 0;
    margin-right: 36px;
    border-radius: 10px;
    border: 1px solid #decfc6;
    @include response(1023px) {
      margin-right: 15px;
    }
    @include response(767px) {
      flex-basis: auto;
      max-width: 100%;
      margin: 0 auto 20px;
    }
  }
  &__nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__nav-item {
    font-family: 'prt', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    position: relative;
    padding: 7px 0;
    &.active {
      padding: 20px 0 24px;
      a {
        background: $main;
      }
    }
    &::after {
      content: '';
      width: 70px;
      height: 1px;
      background: #dfd0c7;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
    a {
      padding: 14px 10px;
      display: block;
      color: #000;
      text-decoration: none;
      transition: .3s ease-in-out;
    }
    @include hover() {
      a {
        color: $green;
      }
    }
  }
  &__main {
    max-width: 792px;
    width: 100%;
    flex-basis: 792px;
    .services-block__image {
      text-align: center;
    }
    &--large {
      max-width: 870px;
      flex-basis: 870px;
      min-width: 0;
    }
    @include response(767px) {
      flex-basis: auto;
      .main-form {
        padding: 0;
      }
    }
  }
  &__list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 45px;
    @include response(767px) {
      justify-content: center;
    }
  }
  &__item {
    max-width: 378px;
    width: 100%;
    flex-basis: 378px;
    margin: 0 9px 18px;
    text-decoration: none;
    @include response(1250px) {
      max-width: 368px;
      flex-basis: 368px;
    }
    @include response(1023px) {
      max-width: 250px;
      flex-basis: 250px;
    }
    @include response(767px) {
      max-width: 47%;
      flex-basis: 47%;
    }
    @include response(629px) {
      max-width: 368px;
      flex-basis: 368px;
    }
    @include hover() {
      .gallery__image {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  &__image {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    img {
      transition: .3s ease-in-out;
    }
  }
  &__title {
    text-align: center;
    font-family: 'prt', sans-serif;
    font-size: 24px;
    color: $green;
    @include response(1023px) {
      font-size: 18px;
    }
    @include response(767px) {
      font-size: 16px;
    }
  }
  &__slider-box {
    margin-bottom: 30px;
  }
  &__slider-main {
    margin-bottom: 9px;
    text-align: center;
  }
  &__slider-item {
    overflow: hidden;
    border-radius: 10px;
    display: block;
    max-width: 693px;
  }
  &__nav-slider {
    background: #dbcbc2;
    padding: 12px 0;
    @include response(1250px) {
      max-width: 580px;
      margin: 0 auto;
    }
    @include response(767px) {
      padding: 12px;
    }
    .slick-arrow {
      height: 100%;
      width: 50px;
      background: #dbcbc2;
      &::before {
        display: none;
      }
      svg {
        fill: $green;
        width: 8px;
        height: 14px;
      }
    }
    .slick-prev {
      left: -50px;
      border-radius: 10px 0 0 10px;
    }
    .slick-next {
      border-radius: 0 10px 10px 0;
      right: -50px;
    }
  }
  &__small-item {
    margin: 0 4px;
    img {
      margin: 0 auto;
    }
  }
}
