.product-page {
  padding-top: 25px;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto 75px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @include response(1366px) {
    padding: 0 15px 0;
  }
  @include response(1250px) {
    padding: 25px 15px 0;
  }
  @include response(767px) {
    flex-direction: column;
    align-items: center;
  }
  &__image {
    max-width: 494px;
    flex-basis: 494px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 30px;
    @include response(1366px) {
      max-width: 430px;
      flex-basis: 430px;
    }
    @include response(767px) {
      flex-basis: auto;
      margin: 0 auto 20px;
    }
  }
  &__info {
    max-width: 645px;
    width: 100%;
    flex-basis: 645px;
    text-align: center;
    padding-top: 15px;
    @include response(767px) {
      flex-basis: auto;
    }
    .services-block__image {
      margin: 0 auto 5px;
      svg {
        width: 49px;
        height: 59px;
      }
    }
  }
  &__title {
    font-family: 'prt', sans-serif;
    font-size: 46px;
    color: $green;
    @include response(767px) {
      font-size: 32px;
    }
  }
  &__number {
    font-size: 16px;
    color: $green;
    margin-bottom: 10px;
  }
  &__text {
    max-width: 320px;
    width: 100%;
    margin: 0 auto 30px;
    text-align: center;
    font-size: 14px;
    color: #000;
    line-height: 1.5;
    @include response(767px) {
      font-size: 16px;
    }
  }
  &__price {
    font-family: 'prt', sans-serif;
    color: $green;
    font-size: 22px;
    margin-bottom: 35px;
    span {
      font-size: 24px;
    }
  }
  &__option-name {
    font-size: 14px;
    color: #000;
    margin-right: 15px;
  }
  &__size {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 22px;
  }
  &__quantity {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
}

.radio-block {
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__item {
    margin: 0 5px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + label {
        width: 30px;
        height: 30px;
        cursor: pointer;
        border: 1px solid #e2d5ce;
        border-radius: 50%;
        font-size: 14px;
        font-weight: bold;
        color: $green;
        @include flexC();
      }
      &:checked {
        + label {
          background: $main;
          border-color: $main;
          color: #fff;
        }
      }
      &:focus {
        + label {
          background: $main;
          border-color: $main;
          color: #fff;
        }
      }
      &:disabled {
        + label {
          background: #ccc;
          border-color: #ccc;
        }
      }
    }
  }
}

.product-quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &__minus {
    background: transparent;
    border: none;
    cursor: pointer;
    @include hover() {
      svg {
        fill: $black;
      }
    }
    svg {
      width: 10px;
      height: 10px;
      fill: #446055;
    }
  }
  &__plus {
    background: transparent;
    border: none;
    cursor: pointer;
    @include hover() {
      svg {
        fill: $black;
      }
    }
    svg {
      width: 10px;
      height: 10px;
      fill: #446055;
    }
  }
  &__input {
    width: 60px;
    height: 28px;
    border: 1px solid #e2d5ce;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 600;
    color: $green;
    text-align: center;
    background: transparent;
    flex-shrink: 0;
  }
}

.other-products {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto 75px;
  @include response(1250px) {
    padding: 25px 15px 0;
  }
  &__list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    @include response(767px) {
      flex-wrap: wrap;
    }
  }
  .services-block__image {
    text-align: center;
  }
}
