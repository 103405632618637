.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  display: none;
  &.popup-video {
    margin: 50px;
    @include response(767px) {
      margin: 10px;
    }
  }
  &.popup--visible {
    display: block;
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }
  &__holder {
    background: #fff;
    padding: 48px 30px;
    position: absolute;    
    left: 50%;
    top: 50%;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    z-index: 999;
    max-width: 460px;
    width: 100%;
    overflow: hidden;
    &--basket-add {
      padding: 70px 30px;
    }
    &--video {
      padding: 0;
      max-width: 1450px;
      height: auto;
      border-radius: 0;
    }
  }
  &__close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 7;
    svg {
      width: 15px;
      height: 15px;
      fill: #000;
    }
  }
  &__title {
    font-size: 24px;
    color: #446055;
    margin-bottom: 15px;
    font-family: 'Lora', serif;
    text-align: center;
  }
  &__text {
    font-size: 14px;
    color: #000;
    text-align: center;
  }
}

.popup-basket__title {
  font-size: 24px;
  color: #446055;
  font-family: 'Lora', serif;
  text-align: center;
}

.iosBugFixCaret {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.header-basket {
  &__popup { 
    border-radius: 10px;
    overflow: hidden;
    min-width: 560px;
    position: absolute;
    right: -50px;
    top: 45px;
    background: #fff;
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.03);
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transition: .3s ease-in-out;
    &::after {
      content: '';
      position: absolute;
      right: 55px;
      top: -28px;
      border: 15px solid transparent;
      border-bottom: 15px solid #fff;
      @include response(1500px) {
        right: 15px;
      }
      @include response(580px) {
        right: 15px;
        top: -20px;
        border: 10px solid transparent;
        border-bottom: 10px solid #fff;
      }
    }
    &.open {
      visibility: visible;
      opacity: 1;
      overflow: visible;
    }
    @include response(1500px) {
      right: -10px;
    }
    @include response(580px) {
      min-width: 300px;
    }
    @include response(580px) {
      right: -5px;
    }
  }
  &__list {
    max-height: 285px;
    overflow: hidden;
    .mCSB_inside > .mCSB_container {
      margin-right: 0;
    }
    @include response(580px) {
      max-height: 228px;
    }
  }
  &__item {
    position: relative;
    padding: 32px 30px 32px 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    @include response(580px) {
      padding: 20px 15px 20px 25px;
    }
  }
  &__delete {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    svg {
      width: 11px;
      height: 11px;
      fill: #ff5a5a;
      stroke: #ff5a5a;
    }
    @include response(580px) {
      left: 5px;
    }
  }
  &__image {
    border-radius: 10px;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 40px;
    @include response(580px) {
      max-width: 90px;
      margin-right: 15px;
    }
  }
  &__title {
    font-family: 'Lora', serif;
    font-size: 18px;
    color: $green;
    margin-bottom: 14px;
    a {
      color: $green;
      text-decoration: none;
      transition: .3s ease-in-out;
      @include hover() {
        color: $main;
      }
    }
    @include response(1250px) {
      font-size: 18px;
    }
    @include response(580px) {
      font-size: 12px;
    }
  }
  &__content {
    max-width: 310px;
    width: 100%;
  }
  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include response(580px) {
      flex-wrap: wrap;
    }
  }
  &__options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .product-quantity {
      margin: 0 8px;
    }
    .product-quantity__input {
      width: 40px;
      height: 25px;
    }
  }
  &__number {
    font-size: 14px;
    color: #808e89;
    margin-right: 5px;
    @include response(580px) {
      font-size: 12px;
    }
  }
  &__qunt {
    font-size: 12px;
    color: #808080;
    background: #f5ede8;
    padding: 6px 9px;
    border-radius: 30px;
  }
  &__edit {
    margin-left: 13px;
    text-decoration: none;
    font-size: 12px;
    color: #999;
    padding-bottom: 3px;
    border-bottom: 1px dotted #999;
    transition: .3s ease-in-out;
    @include hover() {
      color: $main;
      border-color: $main;
    }
  }
  &__price {
    font-family: 'Lora', serif;
    font-size: 18px;
    color: #000;
    margin-left: auto;
    @include response(580px) {
      font-size: 14px;
      margin: 5px 0 0;
    }
  }
  &__bottom {
    padding: 20px 15px 45px;
    .main-button {
      max-width: 210px;
    }
    @include response(580px) {
      padding: 15px 15px 30px;
    }
  }
  &__total-price {
    font-family: 'Lora', serif;
    text-align: center;
    font-size: 24px;
    color: $green;
    margin-bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include response(580px) {
      font-size: 16px;
      margin-bottom: 26px;
    }
    span {
      color: #000;
      margin-left: 8px;
      @include response(580px) {
        font-size: 24px;
        margin-left: 5px;
      }
    }
  }
}