.blog-top-section {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  @include response(1250px) {
    padding: 25px 15px;
    flex-direction: column;
  }
  &__nav {
    @include response(767px) {
      border-radius: 10px;
      border: 1px solid #decfc6;
      max-width: 100%;
      width: 100%;
    }
  }
  &__nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    @include response(767px) {
      flex-direction: column;
    }
  }
  &__nav-item {
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 20px;
    @include response(767px) {
      position: relative;
      font-family: 'prt', sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      padding: 14px 24px 14px 12px;
      width: 100%;
      &::after {
        content: '';
        width: 70px;
        height: 1px;
        background: #dfd0c7;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      background: #ccbcb0;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      transition: .3s ease-in-out;
    }
    a {
      color: $green;
      text-decoration: none;
      @include response(767px) {
        color: #000;
        display: block;
      }
    }
    @include hover() {
      &::before {
        width: 100%;
      }
    }
  }
  &__info {
    display: flex;
    align-items: center;
    .services-block__image {
      margin-right: 15px;
      svg {
        width: 98px;
        height: 70px;
        @include response(767px) {
          width: 85px;
          height: 60px;
        }
      }
    }
    @include response(767px) {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
  &__title {
    font-size: 20px;
    text-transform: uppercase;
    color: $green;
    @include response(767px) {
      font-size: 16px;
    }
    span {
      font-family: 'ese', sans-serif;
      font-size: 42px;
      text-transform: none;
      @include response(767px) {
        font-size: 32px;
      }
    }
  }
}

.news {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @include response(1250px) {
    padding: 0 15px;
  }
  @include response(767px) {
    flex-direction: column;
    align-items: center;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
  }
  &__main {
    max-width: 814px;
    width: 100%;
    flex-basis: 814px;
    margin-right: 15px;
    @include response(767px) {
      flex-basis: auto;
      margin: 0 auto 20px;
    }
    &--single {
      max-width: 800px;
      flex-basis: 800px;
      @include response(767px) {
        flex-basis: auto;
        margin: 0 auto 20px;
      }
    }
  }
  &__list {
    margin: 0 -13px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    @include response(1023px) {
      justify-content: center;
    }
  }
  &__item {
    max-width: 392px;
    width: 100%;
    flex-basis: 392px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ceccc6;
    margin: 0 13px 50px;
    @include response(1250px) {
      max-width: 334px;
      flex-basis: 334px;
    }
    @include response(1023px) {
      max-width: 392px;
      flex-basis: 392px;
      padding-bottom: 20px;
    }
  }
  &__image {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
    a {
      display: block;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(250, 184, 179, 0.5);
        visibility: hidden;
        opacity: 0;
        transition: .3s ease-in-out;
      }
    }
    @include hover() {
      a {
        &::before {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  &__tag {
    font-size: 12px;
    font-weight: 600;
    color: $green;
    text-transform: uppercase;
  }
  &__date {
    font-family: 'prt', sans-serif;
    font-size: 14px;
    color: $green;
  }
  &__title {
    font-family: 'ese', sans-serif;
    font-size: 30px;
    margin-bottom: 5px;
    @include response(1250px) {
      font-size: 26px;
    }
    @include response(767px) {
      font-size: 22px;
    }
    a {
      color: $green;
      text-decoration: none;
      transition: .3s ease-in-out;
      @include hover() {
        color: $main;
      }
    }
  }
  &__text {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-bottom: 5px;
    @include response(767px) {
      font-size: 16px;
    }
  }
  &__link {
    position: relative;
    font-size: 14px;
    color: $green;
    text-decoration: none;
    display: table;
    float: right;
    margin-right: 14px;
    transition: .3s ease-in-out;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: $main;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      transition: .3s ease-in-out;
    }
    @include hover() {
      color: $main;
      &::before {
        width: 60%;
      }
    }
  }
  &__item-large {
    max-width: 100%;
    width: 100%;
    margin-bottom: 40px;
    border-bottom: 1px solid #ceccc6;
    padding: 8px 0 15px;
    .news__image {
      &::before {
        display: none;
      }
    }
    .news__box {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .news__date {
      position: relative;
      max-width: 92px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      margin-right: 15px;
      @include response(767px) {
        max-width: 75px;
      }
      span {
        font-size: 44px;
      }
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 38px;
        width: 1px;
        background: #ceccc6;
      }
    }
    .news__title {
      font-size: 36px;
      @include response(1023px) {
        font-size: 28px;
      }
      @include response(767px) {
        line-height: 24px;
      }
    }
    .news__text {
      font-size: 18px;
      color: $green;
      @include response(1023px) {
        font-size: 14px;
      }
      @include response(767px) {
        font-size: 16px;
      }
    }
  }
  &__sidebar {
    max-width: 334px;
    width: 100%;
    flex-basis: 334px;
    @include response(1023px) {
      max-width: 300px;
      flex-shrink: 0;
    }
    @include response(767px) {
      flex-basis: auto;
    }
  }
}

.sidebar-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  span {
    font-size: 18px;
    text-transform: uppercase;
    color: $green;
    flex-shrink: 0;
    padding: 0 20px;
  }
  &::before {
    content: '';
    display: inline-block;
    background: #ceccc6;
    height: 1px;
    width: 100%;
  }
  &::after {
    content: '';
    display: inline-block;
    background: #ceccc6;
    height: 1px;
    width: 100%;
  }
}

.sidebar-about {
  margin-bottom: 50px;
  &__text {
    text-align: center;
    color: #000;
    font-size: 14px;
    line-height: 1.5;
  }
}

.sidebar-socials {
  margin-bottom: 60px;
  .footer__socials-list {
    justify-content: center;
  }
}

.sidebar-subscribe {
  &__form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
  }
  .form-block {
    max-width: 185px;
    margin-right: 10px;
    @include response(1023px) {
      input {
        font-size: 11px;
      }
    }
  }
  .main-button {
    max-width: 120px;
    margin: 0;
  }
}

.sidebar-news {
  margin-bottom: 40px;
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__image {
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 10px;
    margin-right: 16px;
  }
  &__info {
    max-width: 245px;
    width: 100%;
  }
  &__title {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 5px;
    @include response(767px) {
      font-size: 16px;
    }
    a {
      color: #000;
      transition: .3s ease-in-out;
      text-decoration: none;
      @include hover() {
        color: $main;
      }
    }
  }
  &__date {
    font-family: 'prt', sans-serif;
    font-size: 12px;
    color: $green;
  }
}

.sidebar-instagram {
  &__item {
    overflow: hidden;
    border-radius: 10px;
  }
}

.news-single {
  &__title {
    font-size: 52px;
    color: $green;
    font-family: 'ese', sans-serif;
    margin-bottom: 10px;
    @include response(1023px) {
      font-size: 44px;
    }
    @include response(767px) {
      font-size: 38px;
    }
  }
  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 30px;
    padding-right: 15px;
  }
  &__tag {
    font-size: 12px;
    font-weight: 600;
    color: $green;
    text-transform: uppercase;
  }
  &__date {
    font-family: 'prt', sans-serif;
    color: $green;
    font-size: 17px;
  }
  &__text {
    text-align: center;
    font-size: 16px;
    color: $green;
    line-height: 1.5;
    margin-bottom: 40px;
    .news & {
      @include response(1250px) {
        img {
          width: 100%;
        }
      }
    }
    @include response(767px) {
      font-size: 16px;
    }
    h2 {
      font-size: 24px;
      color: $green;
    }
  }
}

.share-block {
  max-width: 1170px;
  width: 100%;
  margin: 40px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid rgba(111, 111, 111, 0.11);
  border-bottom: 1px solid rgba(111, 111, 111, 0.11);
  @include response(1250px) {
    padding: 20px 15px;
  }
  &__title {
    font-size: 14px;
    color: #000;
    margin-right: 25px;
  }
}

.others-news {
  max-width: 1170px;
  width: 100%;
  margin: 40px auto;
  @include response(1250px) {
    padding: 0 15px;
  }
  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .news__item {
      border-bottom: none;
      @include response(1023px) {
        max-width: 340px;
        flex-basis: 340px;
      }
    }
  }
}

.images-grid {
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 -3px 30px;
  overflow: hidden;
  img {
    margin: 2.5px;
    .news & {
      @include response(1250px) {
        width: 100%;
      }
    }
  }
  @include response(1250px) {
    height: auto;
    img {
      margin: 2.5px auto;
    }
  }
}
