body.page-error {
  .footer {
    display: none;
  }
  .wrapper {
    padding: 0;
  }
}

.error-page {
  min-height: 100vh;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &__box {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__title {
    font-family: 'prt', sans-serif;
    font-size: 296px;
    line-height: 250px;
    color: #fff;
    @include response(767px) {
      font-size: 150px;
      line-height: 152px;
    }
  }
  &__text {
    font-size: 14px;
    color: #fff;
    margin-bottom: 45px;
    @include response(767px) {
      padding: 0 15px;
      font-size: 12px;
      margin-bottom: 25px;
    }
  }
}
