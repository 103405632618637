.top-section {
  position: relative;
  min-height: 680px;
  background-size: cover;
  background-position: center;
  margin-bottom: 45px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
  &__inner {
    max-width: 992px;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include response(1023px) {
      padding: 0 15px;
    }
  }
  &__content {
    margin-top: 94px;
  }
  &__title {
    font-size: 40px;
    text-transform: uppercase;
    color: $main;
    @include response(1023px) {
      font-size: 35px;
    }
    @include response(767px) {
      font-size: 25px;
    }
  }
  &__text {
    color: #fff;
    font-family: 'ese', sans-serif;
    font-size: 90px;
    margin-top: -15px;
    @include response(1023px) {
      font-size: 70px;
    }
    @include response(767px) {
      font-size: 40px;
      line-height: 30px;
      margin-top: 5px;
    }
  }
  @include response(991px) {
    min-height: 480px;
  }
  @include response(767px) {
    min-height: 280px;
  }
  &--margin {
    margin-bottom: 0;
  }
  &--small {
    min-height: 390px;
    background-position: top;
    margin: 0;
  }
  &--header {
    min-height: 94px;
    background-position: top;
    margin: 0;
    @include response(767px) {
      min-height: 70px;
    }
  }
}

.about-text {
  max-width: 680px;
  width: 100%;
  margin: 0 auto 80px;
  @include response(767px) {
    padding: 0 15px;
  }
  &__inner {
    margin-bottom: 50px;
    .services-block__image {
      text-align: center;
    }
  }
}

.about-image {
  margin-bottom: 45px;
  img {
    width: 100%;
  }
}

.images-block {
  margin-bottom: 80px;
  &__list {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  &__item {
    width: 20%;
    opacity: 0;
    img {
      width: 100%;
    }
    &.animations {
      animation: left .4s ease-in-out forwards;
    }
    @include response(1250px) {
      opacity: 1;
    }
  }
}
