@keyframes top {
  0% {
    opacity: 0;
    transform: translate3d(0,20px,0);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}
@keyframes top2 {
  0% {
    opacity: 0;
    transform: translate3d(0,50px,0);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}
@keyframes left {
  0% {
    opacity: 0;
    transform: translate3d(-30px,0,0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@include response-min(1251px) {
  .main {
    .header-basket,
    .header-phone,
    .language-block,
    .logo {
      transform: translateY(-100px);
      transition: .5s ease-in-out;
      &.animate {
        transform: translateY(0);
      }
    }
    @include response(1200px) {
      transform: translateY(0);
    }
    .main-nav__item {
      transform: translateY(-100px);
      transition: .5s ease-in-out;
      &.animate {
        transform: translateY(0);
      }
      @include response(1200px) {
        transform: translateY(0);
      }
    }
  }
}