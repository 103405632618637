.footer {
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  &__inner {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @include response(1250px) {
      padding: 30px 15px;
    }
    @include response(767px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include response(767px) {
      margin: 0 auto 20px;
    }
  }
  &__socials {
    margin-bottom: 20px;
  }
  &__socials-title {
    font-size: 14px;
    color: #000;
    margin-bottom: 12px;
    @include response(767px) {
      text-align: center;
    }
  }
  &__socials-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include response(767px) {
      justify-content: center;
    }
  }
  &__socials-item {
    width: 47px;
    height: 47px;
    border: 1px solid #828282;
    border-radius: 50%;
    margin: 0 7px;
    @include flexC();
    transition: .3s ease-in-out;
    &.social-likes__widget {
      cursor: pointer;
      svg {
        flex-shrink: 0;
      }
    }
    a {
      @include flexC();
      width: 100%;
      height: 100%;
    }
    svg {
      fill: #828282;
      width: 17px;
      height: 17px;
      flex-shrink: 0;
      transition: .3s ease-in-out;
    }
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
    @include hover() {
      border-color: $main;
      svg {
        fill: $main;
      }
    }
  }
  &__nav {
    margin-bottom: 33px;
    @include response(991px) {
      display: none;
    }
  }
  &__nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
  }
  &__nav-item {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 20px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
    a {
      text-decoration: none;
      color: #000;
      transition: .3s ease-in-out;
    }
    @include hover() {
      a {
        color: $main;
      }
    }
  }
  &__bottom {
    padding: 40px 0 40px;
    text-align: center;
    @include response(991px) {
      padding: 40px 0 30px;
    }
    @include response(767px) {
      padding: 0 0 30px;
    }
  }
}

.copyright {
  font-size: 12px;
  color: #000;
}

.subscribe-block {
  max-width: 460px;
  width: 100%;
  &__title {
    font-size: 12px;
    color: #000;
    margin-bottom: 16px;
    @include response(767px) {
      text-align: center;
    }
  }
  form {
    padding-right: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include response(767px) {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
    .main-button {
      margin: 0;
    }
    .form-block {
      @include response(991px) {
        margin-right: 25px;
        max-width: 270px;
        width: 270px;
      }
      @include response(767px) {
        margin: 0 auto 20px;
        max-width: 240px;
        width: 100%;
      }
    }
  }
}