.catalog-page {
  padding-top: 25px;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @include response(1250px) {
    padding: 25px 15px 0;
  }
  @include response(767px) {
    flex-direction: column;
  }
  &__sidebar {
    max-width: 186px;
    width: 100%;
    flex-basis: 186px;
    flex-shrink: 0;
    margin-right: 14px;
    border-radius: 10px;
    border: 1px solid #decfc6;
    @include response(1023px) {
      margin-right: 15px;
    }
    @include response(767px) {
      flex-basis: auto;
      max-width: 100%;
      margin: 0 auto 20px;
    }
  }
  &__main {
    max-width: 970px;
    width: 100%;
    flex-basis: 970px;
    @include response(1250px) {
      .main-form form {
        .form-block {
          margin-right: 10px;
          &:last-of-type {
            margin-right: 20px;
          }
        }
      }
    }
    @include response(1023px) {
      .main-form form {
        flex-direction: column;
        align-items: center;
        .form-block {
          margin: 0 auto 20px;
          &:last-of-type {
            margin: 0 auto 20px;
          }
        }
      }
    }
    @include response(767px) {
      flex-basis: auto;
      .main-form {
        padding: 0;
      }
    }
  }
}

.filters {
  &__item {
    position: relative;
    padding: 8px 0px;
    &::after {
      content: '';
      width: 70px;
      height: 1px;
      background: #dfd0c7;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
    &.active {
      .filters__title {
        background: $main;
      }
    }
  }
  &__title {
    position: relative;
    font-family: 'prt', sans-serif; 
    font-size: 11px;
    color: #000;
    text-transform: uppercase;
    padding: 14px 24px 14px 12px;
    cursor: pointer;
    @include response(767px) {
      font-size: 14px;
    }
    &::after {
      content: '';
      position: absolute;
      right: 10px;
      top: 17px;
      background: #a89c95;
      width: 11px;
      height: 1px;
    }
    &::before {
      content: '';
      position: absolute;
      right: 15px;
      top: 12px;
      background: #a89c95;
      width: 1px;
      height: 11px;
    }
  }
  &__inner {
    height: 0;
    overflow: hidden;
    transition: .3s ease-in-out;
  }
  &__content {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        font-family: 'prt', sans-serif; 
        font-size: 13px;
        border-bottom: 1px solid #decfc6;
        transition: .3s ease-in-out;
        a {
          display: block;
          padding: 9px 25px;
          color: #000;
          text-decoration: none;
        }
        &:last-of-type {
          border-bottom: 0;
        }
        @include hover() {
          background: #efcfc7;
        }
      }
    }
  }
}

.catalog-top {
  height: 220px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 70px;
  margin-bottom: 26px;
  @include response(767px) {
    padding: 0 15px;
  }
  &__title {
    font-family: 'ese', sans-serif;
    font-size: 72px;
    color: #fff;
    margin-bottom: 20px;
    @include response(767px) {
      font-size: 70px;
    }
  }
  &__button {
    max-width: 170px;
    width: 100%;
    height: 48px;
    text-decoration: none;
    font-size: 14px;
    color: #fff;
    border: 1px solid $main;
    border-radius: 30px;
    @include flexC();
    transition: .3s ease-in-out;
    @include response(767px) {
      height: 40px;
    }
    @include hover() {
      background: $main;
    }
  }
}

.product {
  &__list {
    margin: 0 -12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    @include response(1023px) {
      justify-content: center;
    }
  }
  &__item {
    max-width: 309px;
    margin: 0 11px 50px;
    flex-basis: 309px;
    @include response(1250px) {
      max-width: 250px;
      flex-basis: 250px;
    }
  }
  &__image {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
    transition: .3s ease-in-out;
    a {
      position: relative;
      display: block;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        transition: .3s ease-in-out;
      }
      @include hover() {
        &::before {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  &__title {
    text-align: center;
    font-family: 'prt', sans-serif;
    font-size: 24px;
    margin-bottom: 10px;
    a {
      text-decoration: none;
      color: $green;
      transition: .3s ease-in-out;
    }
    @include hover() {
      a {
        color: $main;
      }
    }
  }
  &__price {
    text-align: center;
    font-family: 'prt', sans-serif;
    font-size: 15px;
    color: #000;
  }
}