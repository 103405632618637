.top-title {
  max-width: 1170px;
  width: 100%;
  margin: -10px auto 0;
  font-family: 'prt', sans-serif;
  font-size: 50px;
  color: $green;
  padding-bottom: 20px;
  border-bottom: 1px solid #ceccc6;
  @include response(1250px) {
    padding: 0 15px 20px;
  }
  @include response(767px) {
    margin: 10px auto 0;
    font-size: 38px;
  }
}

.contacts-block {
  max-width: 1170px;
  width: 100%;
  margin: 20px auto 85px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @include response(1250px) {
    padding: 0 15px;
  }
  @include response(1023px) {
    flex-direction: column;
    align-items: center;
  }
  &__title {
    font-family: 'prt', sans-serif;
    font-size: 36px;
    color: $green;
    margin-bottom: 45px;
    @include response(767px) {
      font-size: 24px;
      margin-bottom: 0;
    }
  }
  &__info {
    max-width: 520px;
    width: 100%;
    flex-basis: 520px;
    margin-right: 20px;
    .head-wrap {
      @include response(767px) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }
    @include response(1023px) {
      flex-basis: auto;
      max-width: 100%;
      margin: 0 auto 20px;
    }
  }
  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 259px;
    max-height: 260px;
    @include response(560px) {
      max-height: none;
      height: auto;
    }
  }
  &__item {
    max-width: 248px;
    width: 100%;
    padding-left: 38px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px;
    margin-right: 35px;
    @include response(1250px) {
      margin-right: 10px;
      max-width: 220px;
    }
    @include response(560px) {
      max-width: 100%;
      margin: 0 auto 15px;
    }
    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      fill: $green;
    }
    span {
      font-size: 12px;
      color: #999;
      margin-bottom: 12px;
    }
    address {
      max-width: 210px;
      width: 100%;
      font-style: normal;
      font-size: 14px;
      color: #000;
      margin-bottom: 14px;
      @include response(560px) {
        max-width: 100%;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    a {
      font-size: 14px;
      color: #000;
      text-decoration: none;
      margin-bottom: 6px;
      transition: .3s ease-in-out;
      @include hover() {
        color: $green;
      }
    }
  }
  &__time {
    font-size: 14px;
    color: #000;
    margin-bottom: 3px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__fax {
    font-size: 14px;
    color: #000;
  }
  .services-block__image {
    margin-left: -30px;
    @include response(1250px) {
      margin-left: -10px;
    }
    @include response(767px) {
      margin-left: 0;
    }
    svg {
      width: 85px;
      height: 62px;
    }
  }
}

#map {
  max-width: 628px;
  width: 100%;
  flex-basis: 628px;
  height: 350px;
  border: 2px solid #fff;
  border-radius: 10px;
  @include response(1023px) {
    flex-basis: auto;
    max-width: 100%;
  }
}

.address-btn {
  cursor: pointer;
  transition: .3s ease-in-out;
  &.active {
    color: $main;
  }
  @include hover() {
    color: $main;
  }
}
