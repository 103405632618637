.main-section {
  position: relative;
  height: 100vh;
  // overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  &__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: 1470px;
    width: 100%;
    margin: 0 auto;
    @include response(767px) {
      top: 40%;
    }
  }
  &__content {
    position: relative;
    padding-left: 15px;
    padding-top: 95px;
    @include response(1450px) {
      padding-top: 40px;
    }
    @include response(767px) {
      padding-top: 0;
      padding-left: 35px;
    }
  }
  &__title {
    font-family: 'Antic Didone', serif;
    font-size: 123px;
    color: #fab8b3;
    margin-bottom: 30px;
    letter-spacing: -0.05em;
    line-height: 88px;
    max-width: 480px;
    text-align: right;
    transform: translateX(-28%);
    transition: .5s ease-in-out;
    span {
      margin-right: 30px;
    }
    &.animate {
      transform: translateX(0);
    }
    @include response(1450px) {
      font-size: 70px;
      line-height: 54px;
      max-width: 335px;
    }
    @include response(991px) {
      max-width: 370px;
    }
    @include response(767px) {
      font-size: 42px;
      text-align: left;
      transform: none;
      span {
        margin-right: 0;
      }
    }
  }
  &__nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__nav {
    display: inline-block;
    margin-left: 60px;
    @include response(1450px) {
      margin-left: 75px;
    }
    @include response(767px) {
      margin-left: 0;
    }
  }
  &__nav-item {
    margin-bottom: 20px;
    letter-spacing: 3px;
    position: relative;
    transition: .3s ease-in-out;
    opacity: 0;
    @include response(1450px) {
      margin-bottom: 10px;
    }
    .animations & {
      animation: left .6s ease-in-out .5s forwards;
      @include response(1250px) {
        opacity: 1;
      }
    }
    @include response(1250px) {
      opacity: 1;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 1px;
      background: $main;
      transition: .3s ease-in-out;
    }
    a {
      font-size: 18px;
      font-weight: 300;
      text-transform: uppercase;
      color: #fff;
      text-decoration: none;
      transition: .3s ease-in-out;
      @include response(1450px) {
        font-size: 16px;
      }
      @include response(767px) {
        font-size: 13px;
      }
    }
    @include hover() {
      padding-left: 45px;
      &::before {
        width: 28px;
      }
      a {
        color: $main;
      }
    }
  }
  &__borders {
    width: 320px;
    height: 639px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
    @include response(1450px) {
      height: 385px;
      left: 25px;
      width: 265px;
    }
    @include response(1250px) {
      display: none;
    }
    .main-section__border-1 {
      width: 0;
      height: 2px;
      position: absolute;
      right: 0;
      top: 0;
      background: #fff;
      transition: .5s ease-in-out;
      &.animate {
        width: 100%;
      }
    }
    .main-section__border-2 {
      width: 2px;
      height: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #fff;
      transition: .5s ease-in-out;
      &.animate {
        height: 100%;
      }
    }
    .main-section__border-3 {
      width: 0;
      height: 2px;
      position: absolute;
      right: 0;
      bottom: 0;
      background: #fff;
      transition: .5s ease-in-out;
      &.animate {
        width: 100%;
      }
    }
    .main-section__border-4 {
      width: 2px;
      height: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      background: #fff;
      transition: .5s ease-in-out;
      &.animate {
        height: 100%;
      }
    }
  }
  &__bottom-text {
    line-height: 127px;
    font-size: 181px;
    letter-spacing: -0.08em;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: 'gt', sans-serif;
    &::first-letter {
      font-family: 'gh', sans-serif;
      color: #E7DBC0;
    }
    @include response(1250px) {
      font-size: 100px;
      line-height: 80px;
    }
    @include response(767px) { 
      font-size: 45px;
      line-height: 45px;
    }
  }
}

#video {
  // min-width: 100%;
  // min-height: 100%;
  // width: auto;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.top-video {
  &__box {
    position: absolute;
    top: 50%;
    right: 45%;
    z-index: 1;
    border-radius: 50%;
    transform: translateY(-50%);
    z-index: 4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 245px;
    width: 100%;
    @include response(1023px) {
      right: 25%;
    }
    @include response(767px) {
      // display: none;
      width: auto;
      right: 10%;
      top: 70%;
    }
  }
  &__text {
    position: relative;
    font-size: 12px;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    @include response(1023px) {
      display: none;
    }
    &::before {
      content: '';
      position: absolute;
      left: -75px;
      top: 50%;
      transform: translateY(-50%);
      width: 61px;
      height: 1px;
      background: #F2F2F2;;
    }
  }
}

.top-video__btn {
  position: relative;
  cursor: pointer;
  width: 92px;
  height: 92px;
  border: 1px solid #F2F2F2;
  border-radius: 50%;
  transform: translate3d(0,0,0);
  @include flexC();
  svg {
    fill: #DFCFAA;
    width: 22px;
    height: 25px;
  }
  &::before {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    bottom: -15px;
    right: -15px;
    border: 1px solid #F2F2F2;
    opacity: 0.5;
    border-radius: 50%;
    animation: circle 3s ease infinite 1s;
  }
  &::after {
    content: '';
    position: absolute;
    top: -25px;
    left: -25px;
    bottom: -25px;
    right: -25px;
    border: 1px solid #F2F2F2;
    opacity: 0.5;
    border-radius: 50%;
    animation: circle 3s ease infinite 1s;
    transition: .4s ease-in-out;
  }
  @include response(767px) {
    width: 65px;
    height: 65px;
    &::before { 
      top: -10px;
      left: -10px;
      bottom: -10px;
      right: -10px;
    }
    &::after {
      top: -15px;
      left: -15px;
      bottom: -15px;
      right: -15px;
    }
  }
}

.pause-btn {
  display: none;
}

@keyframes circle {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

.main-content {
  background: #fbd4d4;
  text-align: center;
  padding: 40px 0 60px;
  @include response(991px) {
    padding: 40px 15px 60px;
  }
  &__image {
    margin-bottom: 25px;
    svg {
      width: 109px;
      height: 131px;
      fill: rgba(79, 104, 93, 0.55);
      @include response(767px) {
        width: 90px;
        height: 70px;
      }
    }
  }
  &__title {
    font-family: 'ese', sans-serif;
    font-size: 72px;
    color: #446055;
    margin-bottom: 15px;
    @include response(991px) {
      font-size: 56px;
    }
    @include response(767px) {
      font-size: 48px;
      line-height: 46px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    @include response(991px) {
      font-size: 14px;
      line-height: 1.2;
    }
  }
}

.services-block {
  text-align: center;
  padding: 84px 0 0;
  @include response(767px) {
    padding: 45px 0 0;
  }
  &__list {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 120px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    .services-block & {
      @include response(1250px) {
        flex-direction: column;
      }
    }
    &.services-block__slider {
      .slick-arrow {
        width: 50px;
        height: 50px;
        border: 1px solid $main;
        background: #fff;
        border-radius: 50%;
        text-align: center;
        z-index: 3;
        transition: .3s ease-in-out;
        svg {
          width: 9px;
          height: 18px;
          fill: $main;
        }
        &::before {
          display: none;
        }
        @include hover() {
          background: rgba(255, 255, 255, 0.5);
        }
      }
      .slick-prev {
        left: 5px;
      }
      .slick-next {
        right: 5px;
      }
      @include response(1250px) {
        flex-direction: row;
      }
    }
    @include response(1250px) {
      padding: 0 15px;
    }
    @include response(991px) {
      padding: 0;
    }
  }
  &__slider {
    margin-bottom: 45px;
    &.slick-dotted.slick-slider {
      margin-bottom: 85px;
    }
    .slick-dots {
      position: static;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 10px;
      padding: 0 20px;
      li {
        width: 29px;
        height: 25px;
        margin: 0 4px;
        @include response(767px) {
          width: 19px;
          height: 15px;
        }
        button {
          opacity: 1;
          width: 29px;
          height: 25px;
          background: #C4C4C4;
          border-radius: 0;
          @include response(767px) {
            width: 19px;
            height: 15px;
          }
        }
        &.slick-active {
          button {
            background: #fff;
          }
        }
      }
    }
  }
  &__item {
    position: relative;
    display: block;
    max-width: 370px;
    width: 100%;
    flex-basis: 370px;
    height: 370px;
    margin: 0 15px;
    border-radius: 10px;
    overflow: hidden;
    transition: .3s ease-in-out;
    @include response(1250px) {
      max-width: 350px;
      flex-basis: 350px;
      height: 350px;
      margin: 0 18px;
    }
    @include response(767px) {
      max-width: 290px;
      flex-basis: 290px;
      height: 290px;
      margin: 0 15px;
    }
    @include response(480px) {
      max-width: 100%;
      flex-basis: auto;
      margin: 0;
      height: auto;
    }
    @include hover() {
      .services-block__overlay {
        visibility: visible;
        opacity: 1;
        overflow: visible;
      }
      .services-block__title {
        right: 14px;
      }
    }
  }
  &__image {
    margin-bottom: 14px;
    svg {
      width: 135px;
      height: 97px;
      fill: rgba(79, 104, 93, 0.55);
      @include response(767px) {
        width: 85px;
        height: 60px;
      }
    }
  }
  &__title {
    max-width: 260px;
    width: 100%;
    text-align: left;
    font-family: 'prt', sans-serif;
    font-size: 24px;
    color: #000;
    line-height: 23px;
    letter-spacing: -0.045em;
    span {
      font-size: 29px;
      letter-spacing: -0.05em;
      display: inline-block;
      font-family: 'gt', sans-serif;
      &::first-letter {
        color: #fff;
        font-family: 'gh', sans-serif;
      }
    }
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    right: 0;
    bottom: 24px;
    padding: 10px 30px 8px 40px;
    z-index: 2;
    transition: .3s ease-in-out;
    @include response(767px) {
      font-size: 24px;
      max-width: 100%;
      text-align: right;
      span {
        display: block;
      }
    }
  }
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    border-radius: 10px;
    overflow: hidden;
    transition: .3s ease-in-out;
    &::before {
      content: '';
      border: 1px solid #767675;
      width: 344px;
      position: absolute;
      top: 13px;
      left: 13px;
      right: 13px;
      bottom: 13px;
      border-radius: 10px;
    }
  }
  &__box {
    img {
      @include response(480px) {
        width: 100%;
      }
    }
  }
}

.boutique-block {
  text-align: center;
  margin-bottom: 100px;
  @include response(767px) {
    margin-bottom: 60px;
  }
  &__image {
    svg {
      fill: rgba(79, 104, 93, 0.55);
      width: 137px;
      height: 102px;
    }
  }
  &__list {
    max-width: 1215px;
    width: 100%;
    margin: 0 auto 55px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @include response(1250px) {
      justify-content: center;
    }
    @include response(767px) {
      margin-bottom: 30px;
    }
  }
  &__item {
    position: relative;
    max-width: 375px;
    width: 100%;
    flex-basis: 375px;
    margin: 0 15px 30px;
    text-decoration: none;
    opacity: 0;
    &.animations {
      animation: top2 .8s ease-in-out forwards;
    }
    @include response(1250px) {
      margin: 0 15px 30px;
    }
    @include response(991px) {
      max-width: 335px;
      flex-basis: 335px;
    }
    @include response(480px) {
      max-width: 100%;
      flex-basis: auto;
      margin: 0 0 30px;
    }
    @include hover() {
      .boutique-block__item-box {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  &__item-box {
    border-radius: 10px;
    overflow: hidden;
    max-height: 475px;
    margin-bottom: 15px;
    img {
      transition: .3s ease-in-out;
      @include response(480px) {
        width: 100%;
      }
    }
  }
  &__item-title {
    font-family: 'prt', sans-serif;
    font-size: 24px;
    line-height: 18px;
    color: #000;
    position: absolute;
    max-width: 192px;
    width: 100%;
    text-align: left;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    right: 0;
    bottom: 55px;
    padding: 10px 20px 5px;
    z-index: 2;
    transition: .3s ease-in-out;
    span {
      font-size: 29px;
      letter-spacing: -0.05em;
      display: inline-block;
      font-family: 'gt', sans-serif;
      &::first-letter {
        color: #fff;
        font-family: 'gh', sans-serif;
      }
    }
  }
}

.portfolio-block {
  text-align: center;
  margin-bottom: 77px;
  @include response(1500px) {
    padding: 0 15px;
  }
  @include response(767px) {
    padding: 0;
  }
  .main-title {
    margin-bottom: 58px;
    @include response(767px) {
      margin-bottom: 0;
    }
  }
  &__image {
    svg {
      fill: rgba(79, 104, 93, 0.55);
      width: 109px;
      height: 131px;
      @include response(767px) {
        width: 90px;
        height: 70px;
      }
    }
  }
  &__list {
    max-width: 1350px;
    width: 100%;
    margin: 0 auto 45px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 11px;
      background: #ecdbd1;
      z-index: 1;
      @include response(480px) {
        display: none;
      }
    }
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 11px;
      background: #ecdbd1;
      z-index: 1;
      @include response(480px) {
        display: none;
      }
    }
  }
  &__slider {
    .slick-arrow {
      width: 40px;
      height: 36px;
      background: rgba(255, 255, 255, 0.46);
      text-align: center;
      z-index: 3;
      transition: .3s ease-in-out;
      svg {
        width: 30px;
        height: 36px;
        fill: #fff;
      }
      &::before {
        display: none;
      }
      @include hover() {
        background: rgba(255, 255, 255, 0.9);
      }
    }
    .slick-prev {
      left: 11px;
      text-align: right;
      @include response(480px) {
        left: 0;
      }
    }
    .slick-next {
      right: 11px;
      text-align: left;
      @include response(480px) {
        right: 0;
      }
    }
  }
  &__item {
    max-width: 248px;
    width: 248px;
    flex-basis: 248px;
    max-height: 248px;
    border-radius: 10px;
    margin: 0 11px;
    overflow: hidden;
    @include response(767px) {
      max-width: none;
      width: 100%;
      flex-basis: auto;
      max-height: none;
      img {
        width: 100%;
      }
    }
    @include response(480px) {
      max-width: 100%;
      flex-basis: auto;
      margin: 0 0 30px;
    }
  }
}

.main-about {
  position: relative;
  min-height: 800px;
  margin-bottom: 125px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  backface-visibility: hidden;
  @include response(1250px) {
    min-height: 600px;
  }
  @include response(991px) {
    min-height: 500px;
    margin-bottom: 85px;
    background-attachment: initial;
  }
  @include response(767px) {
    min-height: 400px;
    margin-bottom: 45px;
  }
  @include response(480px) {
    background-size: 100%;
    background-attachment: initial;
    min-height: 155px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
  }
  &__box {
    width: 142px;
    height: 0;
    background: rgba(255, 255, 255, 0.64);
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    padding: 0;
    transition: .5s ease-in-out;
    &.animations {
      height: 396px;
      padding: 25px 20px;
    }
    @include response(1250px) {
      height: 396px;
      padding: 25px 20px;
    }
    @include response(767px) {
      width: 70px;
      height: 250px;
      padding: 10px;
    }
    @include response(480px) {
      height: 105px;
      width: 38px;
    }
  }
  &__title {
    width: 100%;
    font-family: 'prt', sans-serif;
    line-height: 57px;
    font-size: 67px;
    letter-spacing: -0.05em;
    color: #fff;
    transform: translateY(-10px);
    overflow: hidden;
    opacity: 0;
    overflow: hidden;
    transition: 1.2s ease-in-out;
    &.visible {
      transform: translateY(0);
      overflow: visible;
      opacity: 1;
      overflow: visible;
    }
    @include response(1250px) {
      transform: translateY(0);
      overflow: visible;
      opacity: 1;
      overflow: visible;
    }
    @include response(767px) {
      font-size: 36px;
      line-height: 35px;
    }
    @include response(480px) {
      font-size: 24px;
      line-height: 20px;
    }
  }
  &__link {
    line-height: 20px;
    font-size: 28px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #fff;
    text-decoration: none;
    position: absolute;
    bottom: 55px;
    left: 50%;
    margin-left: 40px;
    transform: translateX(-60%);
    opacity: 0;
    overflow: hidden;
    transition: 2.2s ease-in-out;
    &.visible {
      overflow: visible;
      opacity: 1;
      overflow: visible;
      transform: translateX(-50%);
    }
    svg {
      display: block;
      margin: 0 auto 15px;
      width: 33px;
      height: 16px;
      fill: #fff;
    }
    @include response(1250px) {
      overflow: visible;
      opacity: 1;
      overflow: visible;
      transform: translateX(-50%);
    }
    @include response(767px) {
      bottom: 40px;
      margin-left: 45px;
    }
    @include response(480px) {
      bottom: 20px;
      font-size: 14px;
      margin-left: 27px;
      svg {
        display: none;
      }
    }
  }
}

.main-triggers {
  margin-bottom: 90px;
  @include response(767px) {
    margin-bottom: 45px;
    display: none;
  }
  .main-title {
    margin-bottom: 60px;
    &::after {
      display: none;
    }
    @include response(767px) {
      margin-bottom: 55px;
    }
  }
  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include response(580px) {
      flex-wrap: wrap;
    }
  }
  &__item {
    position: relative;
    max-width: 33.33%;
    width: 100%;
    flex-basis: 33.33%;
    overflow: hidden;
    img {
      width: 100%;
      transition: .3s ease-in-out;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    &.animations {
      .main-triggers__box {
        height: 100%;
      }
      .main-triggers__text {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @include response(580px) {
      max-width: 100%;
      flex-basis: 100%;
    }
    @include hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  &__box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 95px;
    height: 0;
    background: rgba(252, 201, 201, 0.50);
    transition: .5s ease-in-out;
    @include response(1250px) {
      height: 100%;
    }
  }
  &__text {
    font-family: 'prt', sans-serif;
    line-height: 42px;
    font-size: 50px;
    letter-spacing: -0.05em;
    color: #fff;
    position: absolute;
    left: 12px;
    bottom: 115px;
    z-index: 3;
    opacity: 0;
    transform: translateY(-20px);
    transition: 1.5s ease-in-out;
    @include response(1250px) {
      opacity: 1;
      transform: translateY(0);
    }
    @include response(1023px) {
      font-size: 34px;
    }
    &::first-letter {
      font-size: 96px;
      font-family: 'gh', sans-serif;
      @include response(1023px) {
        font-size: 74px;
      }
    }
  }
}


.main-form {
  max-width: 866px;
  width: 100%;
  margin: 0 auto 80px;
  flex-basis: 866px;
  border-radius: 25px;
  @include response(1250px) {
    .gallery--margin-mobile & {
      margin: 0 auto;
    }
  }
  @include response(991px) {
    padding: 0 15px;
  }
  &__inner {
    border-radius: 25px;
    background: #f6efeb;
    padding: 60px 75px 70px;
    box-shadow: inset 0 0 0 16px #f1e5de;
    border: 1px solid #fff;
    @include response(767px) {
      padding: 60px 35px 70px;
    }
  }
  &__title {
    font-size: 18px;
    color: $green;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 35px;
    @include response(991px) {
      font-size: 16px;
    }
    @include response(767px) {
      font-size: 14px;
    }
  }
  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 29px;
    @include response(767px) {
      flex-direction: column;
    }
    .main-button {
      margin: 0;
      @include response(767px) {
        margin: 10px 0 0;
      }
    }
    .form-block {
      @include response(991px) {
        margin: 0 10px;
        &:last-of-type {
          margin-right: 25px;
          @include response(767px) {
            margin: 0 auto 20px;
          }
        }
      }
      @include response(767px) {
        margin: 0 auto 20px;
      }
    }
  }
  &__text {
    text-align: center;
    font-size: 14px;
    color: $green;
    @include response(767px) {
      line-height: 1.5;
    }
    a {
      text-decoration: none;
      color: $main;
      border-bottom: 1px solid $main;
      transition: .3s ease-in-out;
      @include hover() {
        opacity: .7;
      }
    }
  }
}

.main-blog {
  text-align: center;
  margin-bottom: 85px;
  @include response(767px) {
    margin-bottom: 45px;
  }
  &__list {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    @include response(1250px) {
      justify-content: center;
    }
    @include response(767px) {
      margin: 0 auto 15px;
    }
  }
  &__item {
    position: relative;
    max-width: 572px;
    width: 100%;
    flex-basis: 572px;
    border: 1px solid #c7c0b6;
    border-radius: 10px;
    transition: .3s ease-in-out;
    @include response(1250px) {
      margin: 0 auto 35px;
    }
    @include response(480px) {
      border: none;
      border-top: 1px solid #c7c0b6;
      border-radius: 0;
    }
    @include hover() {
      border-color: $main;
      .main-blog__link {
        &::before {
          width: 50%;
        }
      }
      .main-blog__image {
        &::before {
          background: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  &__date {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    font-family: 'prt', sans-serif;
    font-size: 18px;
    color: $green;
    padding: 0 35px;
    background: #ecdbd1;
    @include response(767px) {
      top: -5px;
      font-size: 12px;
      padding: 0 15px;
    }
  }
  &__inner {
    display: block;
    padding: 25px;
    position: relative;
    cursor: pointer;
    @include response(480px) {
      padding: 25px 0 0;
    }
  }
  &__image {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      z-index: 2;
      border-radius: 10px;
      transition: .3s ease-in-out;
    }
  }
  &__title {
    max-width: 340px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    color: #fff;
    font-family: 'prt', sans-serif;
    font-size: 24px;
    @include response(767px) {
      max-width: 260px;
      font-size: 14px;
      line-height: 1.2;
    }
  }
  &__link {
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    @include response(767px) {
      bottom: 50px;
      font-size: 12px;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: #ccbcb0;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      transition: .3s ease-in-out;
    }
  }
}

.instagram-block {
  text-align: center;
  margin-bottom: 95px;
  @include response(767px) {
    margin-bottom: 45px;
  }
  &__image {
    svg {
      fill: rgba(79, 104, 93, 0.55);
      width: 109px;
      height: 131px;
      @include response(767px) {
        width: 90px;
        height: 70px;
      }
    }
  }
  &__list {
    max-width: 885px;
    width: 100%;
    margin: 0 auto 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    @include response(991px) {
      justify-content: center;
    }
  }
  &__item {
    border-radius: 10px;
    overflow: hidden;
    margin: 0 8px 16px;
    opacity: 0;
    &.animations {
      animation: top .7s ease-in-out forwards;
    }
    @include response(589px) {
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        display: none;
      }
    }
    @include response(480px) {
      width: 100%;
      margin: 0 0 16px;
      img {
        width: 100%;
      }
    }
  }
  &__link {
    position: relative;
    color: $green;
    text-transform: uppercase;
    text-decoration: none;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: $main;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      transition: .3s ease-in-out;
    }
    @include hover() {
      &::before {
        width: 50%;
      }
    }
  }
}

.content-block {
  max-width: 1010px;
  width: 100%;
  margin: 0 auto 50px;
  @include response(1250px) {
    padding: 0 15px;
  }
  &__image {
    text-align: center;
    margin-bottom: 30px;
    svg {
      width: 135px;
      height: 97px;
      fill: rgba(79, 104, 93, 0.55);
      @include response(767px) {
        width: 90px;
        height: 70px;
      }
    }
  }
}
.animations-box {
  &__children {
    opacity: 0;
    @include response(1250px) {
      opacity: 1;
    }
    &.animations {
      animation: top2 .8s ease-in-out forwards;
    }
  }
}
.boutique-block__item,
.instagram-block__item {
  @include response(1250px) {
    opacity: 1;
  }
}

.top-main-block {
  background: #AAAAAA;
  height: auto;
  padding: 10px 0 40px;
  &__main-text {
    font-family: 'gt', sans-serif;
    line-height: 127px;
    font-size: 181px;
    text-align: right;
    letter-spacing: -0.08em;
    text-transform: uppercase;
    color: #FFFFFF;
    display: table;
    padding-top: 10px;
    span {
      display: inline-block;
      &::first-letter {
        font-family: 'gh', sans-serif;
      }
    }
    @include response(1250px) {
      padding-top: 0;
      font-size: 100px;
      line-height: 80px;
    }
    @include response(767px) { 
      font-size: 45px;
      line-height: 45px;
      margin-bottom: 10px;
    }
  }
  &__title {
    font-family: 'gl', sans-serif;
    line-height: normal;
    font-size: 36px;
    text-transform: uppercase;
    color: #E7D8B4;
    padding: 20px 40px;
    @include response(767px) { 
      font-size: 14px;
      padding: 0 50px 20px;
    }
  }
  &__text {
    max-width: 340px;
    width: 100%;
    font-family: 'gl', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    color: #E8DBB6;
    padding: 0 40px;
    @include response(767px) { 
      padding: 0 30px 0 50px;
      font-size: 11px;
      color: #524F44;
    }
  }
  &__box {
    @include response(767px) { 
      padding: 0 50px;
    }
  }
}