.back-button {
  display: block;
  max-width: 1170px;
  width: 100%;
  margin: 30px auto 20px;
  color: $green;
  font-family: 'prt', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 40px;
  transition: .3s ease-in-out;
  svg {
    fill: $green;
    width: 9px;
    height: 18px;
    margin-right: 12px;
    vertical-align: text-top;
    transition: .3s ease-in-out;
  }
  @include hover() {
    color: $main;
    svg {
      fill: $main;
    }
  }
  @include response(767px) {
    font-size: 12px;
    svg {
      width: 5px;
      height: 14px;
    }
  }
}

.basket-block {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto 45px;
  &__inner {
    @include response(1250px) {
      padding: 0 15px;
      overflow-x: auto;
    }
  }
  &__head {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #decfc6;
    border-bottom: 1px solid #decfc6;
    padding: 18px 0px;
    @include response(1250px) {
      display: none;
    }
  }
  &__head-item {
    font-size: 14px;
    font-weight: 600;
    color: #808e89;
    width: 15%;
    text-align: center;
    &:first-child {
      width: 51%;
    }
  }
  &__item {
    position: relative;
    padding: 25px 0 25px 58px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #decfc6;
    @include response(767px) {
      padding: 15px 0 15px 15px;
    }
  }
  &__delete {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    @include response(767px) {
      left: -5px;
    }
    svg {
      width: 11px;
      height: 11px;
      fill: $main;
      stroke: $main;
    }
  }
  &__image {
    overflow: hidden;
    border-radius: 10px;
    margin-right: 40px;
    flex-shrink: 0;
    @include response(767px) {
      width: 65px;
      margin-right: 15px;
    }
  }
  &__info {
    max-width: 330px;
    width: 100%;
    margin-right: 50px;
    @include response(1250px) {
      max-width: 262px;
      width: 100%;
      margin-right: 25px;
    }
    @include response(991px) {
      max-width: 225px;
      margin-right: 15px;
    }
    @include response(767px) {
      max-width: 100%;
      margin: 0 0 5px;
    }
  }
  &__title {
    font-size: 24px;
    color: $green;
    margin-bottom: 10px;
    text-decoration: none;
    transition: .3s ease-in-out;
    @include response(991px) {
      display: block;
      font-size: 20px;
      margin-bottom: 5px;
    }
    @include response(767px) {
      font-size: 14px;
    }
    @include hover() {
      color: $main;
    }
  }
  &__number {
    font-size: 16px;
    color: #808e89;
    @include response(767px) {
      font-size: 12px;
    }
  }
  &__price {
    max-width: 160px;
    width: 100%;
    font-size: 24px;
    color: #808e89;
    text-align: center;
    margin-right: 20px;
    @include response(991px) {
      display: none;
    }
  }
  &__qunt {
    max-width: 160px;
    width: 100%;
    margin-right: 25px;
    @include response(767px) {
      max-width: none;
      width: auto;
      margin-right: 15px;
    }
    .product-quantity__input {
      background: #fff;
      @include response(767px) {
        width: 40px;
        height: 22px;
      }
    }
  }
  &__total {
    max-width: 160px;
    width: 100%;
    font-size: 24px;
    color: #000;
    text-align: center;
    @include response(767px) {
      font-size: 14px;
    }
  }
  &__bottom {
    display: flex;
    flex-basis: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px 0 60px;
    @include response(767px) {
      flex-direction: column;
      padding: 0 15px;
    }
  }
  &__text {
    max-width: 560px;
    width: 100%;
    font-size: 14px;
    font-style: italic;
    color: #756d68;
    line-height: 1.5;
    @include response(1250px) {
      margin-right: 25px;
    }
    span {
      font-weight: bold;
    }
  }
  &__bottom-total {
    max-width: 410px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .total-text {
      font-size: 24px;
      color: #808e89;
      @include response(767px) {
        font-size: 18px;
      }
    }
    .total-price {
      font-family: 'prt', sans-serif;
      font-size: 36px;
      color: #000;
      @include response(767px) {
        font-size: 22px;
      }
    }
  }
  &__info-box {
    max-width: 970px;
    display: flex;
    width: 100%;
    @include response(767px) {
      flex-direction: column;
    }
  }
  &__options {
    display: flex;
    max-width: 530px;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include response(767px) {
      justify-content: space-between;
    }
  }
}

.checkout-block {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto 80px;
  @include response(1250px) {
    padding: 0 15px;
  }
  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include response(1250px) {
      flex-direction: column;
    }
  }
  &__title {
    font-family: 'prt', sans-serif;
    font-size: 50px;
    color: $green;
    margin-bottom: 30px;
    @include response(1023px) {
      font-size: 30px;
    }
    @include response(767px) {
      font-size: 24px;
    }
  }
  &__form {
    max-width: 820px;
    width: 100%;
    flex-basis: 820px;
    @include response(1250px) {
      flex-basis: auto;
    }
  }
  &__sidebar {
    max-width: 298px;
    width: 100%;
    flex-basis: 298px;
    background: #f5ece7;
    padding: 30px 40px 20px;
    border-radius: 10px;
    box-shadow: inset 0 0 0 16px #f1e5de;
    @include response(1250px) {
      flex-basis: auto;
    }
  }
  &__sidebar-title {
    font-size: 18px;
    text-transform: uppercase;
    text-align: left;
    color: $green;
    margin-bottom: 15px;
  }
  &__sidebar-text {
    font-size: 13px;
    color: $green;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  &__sidebar-connection {
    font-size: 16px;
    color: $main;
    margin: 0 auto 10px;
    display: block;
    text-align: center;
  }
  &__sidebar-phone {
    color: #808e89;
    margin: 0 auto 10px;
    display: block;
    text-align: center;
    font-size: 18px;
    text-decoration: none;
  }
}

.form-block {
  &__inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #decfc6;
    padding-bottom: 20px;
    margin-bottom: 15px;
    &:last-of-type {
      border: none;
    }
    @include response(767px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__text {
    font-family: 'prt', sans-serif;
    font-size: 18px;
    color: $green;
    @include response(1023px) {
      font-size: 16px;
      margin-right: 15px;
      .checkout-block & {
        flex: 0 0 224px;
        max-width: 224px;
      }
    }
    @include response(767px) {
      margin: 0 auto 10px;
      .checkout-block & {
        flex: 0 0 auto;
        max-width: none;
      }
    }
    small {
      margin-top: 5px;
      font-size: 14px;
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-style: italic;
      color: #756d68;
      @include response(1023px) {
        font-size: 12px;
      }
    }
  }
  &__radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 505px;
    width: 100%;
    flex-basis: 505px;
    padding-right: 30px;
    @include response(767px) {
      flex-basis: auto;
    }
    @include response(580px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .radio-block {
      @include response(580px) {
        margin-bottom: 5px;
      }
      input[type="radio"] {
        position: absolute;
        opacity: 0;
        + label {
          display: block;
          font-size: 16px;
          color: $green;
          cursor: pointer;
          margin-bottom: 2px;
          @include response(767px) {
            font-size: 16px;
          }
          &:before {
            content: '';
            background: transparent;
            border-radius: 100%;
            border: 1px solid transparent;
            display: inline-block;
            width: 25px;
            height: 25px;
            position: relative;
            top: 0;
            margin-right: 12px;
            vertical-align: middle;
            cursor: pointer;
            text-align: center;
            box-shadow: inset 0 0 0 5px #fff;
            transition: all 250ms ease;
          }
        }
        &:checked {
          + label {
            &:before {
              border-color: $main;
              background-color: $main;
              box-shadow: inset 0 0 0 5px #fff;
            }
          }
        }
        &:focus {
          + label {
            &:before {
              outline: none;
            }
          }
        }
        &:disabled {
          + label {
            &:before {
              box-shadow: inset 0 0 0 2px #fff;
              border-color: #efefef;
            }
          }
        }
        + label {
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  &__group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 505px;
    width: 100%;
    flex-basis: 505px;
    @include response(767px) {
      flex-basis: auto;
    }
    @include response(580px) {
      flex-direction: column;
      align-items: flex-start;
      .form-block {
        max-width: 100%;
        margin: 0 auto 20px;
      }
    }
  }
  &__column {
    max-width: 505px;
    width: 100%;
    flex-basis: 505px;
    @include response(767px) {
      flex-basis: auto;
    }
    .form-block {
      max-width: 100%;
      margin-bottom: 26px;
    }
  }
}

body.success {
  .top-title {
    border: none;
  }
}

.chekout-success {
  max-width: 510px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include response(767px) {
    min-height: 60vh;
  }
  &__title {
    font-family: 'prt', sans-serif;
    font-size: 30px;
    color: #446055;
    margin-bottom: 16px;
    @include response(767px) {
      font-size: 22px;
    }
  }
  &__text {
    font-size: 14px;
    color: #000;
    margin-bottom: 32px;
    @include response(767px) {
      font-size: 16px;
      padding: 0 15px;
    }
  }
}

.basket-block__nav-icon {
  text-align: right;
  padding: 0 20px;
  margin: -25px 0 10px;
  display: none;
  animation: handleft 1.5s linear infinite;
  svg {
    fill: #446055;
  }
  @include response(1200px) {
    display: block;
  }
}

@keyframes handleft {
  0% {
    transform: translate3d(0,0,0)
  }
  25% {
    transform: translate3d(5px,0,0)
  }
  50% {
    transform: translate3d(0,0,0)
  }
  75% {
    transform: translate3d(-5px,0,0)
  }
  100% {
    transform: translate3d(0,0,0)
  }
}