.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // background: rgba(213, 163, 160, 0.2);
  z-index: 7;
  &__overlay {
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    position: absolute;
    background: rgba(213, 163, 160, 0.2);
    transition: 2s ease-in-out;
    &.animate {
      width: 100%;
    }
  }
  &__inner {
    max-width: 1330px;
    width: 100%;
    height: 94px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include response(1450px) {
      padding: 0 15px;
    }
    @include response(1024px) {
      justify-content: flex-start;
    }
    @include response(767px) {
      height: 70px;
      justify-content: space-between;
    }
  }
}

.language-block {
  position: relative;
  @include response(1024px) {
    margin: 0 50px;
  }
  @include response(767px) {
    margin: 0;
    display: none;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -26px;
    width: 1px;
    height: 23px;
    background: #5f565a;
    @include response(1250px) {
      display: none;
    }
  }
  &__current {
    cursor: pointer;
    position: relative;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    &::after {
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      content: '';
      display: block;
      height: 4px;
      width: 4px;
      margin-top: -4px;
      pointer-events: none;
      position: absolute;
      right: -15px;
      top: 50%;
      transform-origin: 66% 66%;
      transform: rotate(45deg);
      transition: all 0.15s ease-in-out;
    }
    &.active {
      &::after {
        transform: rotate(-135deg);
      }
    }
    @include response(767px) {
      &::after {
        display: none;
      }
    }
  }
  &__list {
    min-width: 35px;
    position: absolute;
    top: 16px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transition: .3s ease-in-out;
    @include response(767px) {
      position: static;
      visibility: visible;
      opacity: 1;
      overflow: visible;
    }
    &.open {
      visibility: visible;
      opacity: 1;
      overflow: visible;
    }
    a {
      color: $main ;
      text-decoration: none;
      font-size: 12px;
      text-transform: uppercase;
      padding: 2px 0;
      display: block;
      transition: .3s ease-in-out;
      @include hover() {
        text-decoration: underline;
      }
    }
  }
}

.main-nav__buttons {
  .header-language {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .language-block__current {
    margin-right: 5px;
    color: $main;
  }
  .language-block__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      margin: 0 5px;
      color: #fff;
    }
  }
}

.header-phone {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 12px;
  font-weight: 600;
  @include response(1250px) {
    font-size: 11px;
  }
  @include response(1024px) {
    margin: 0 50px;
    font-size: 12px;
  }
  @include response(767px) {
    margin: 0;
    display: none;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  svg {
    width: 16px;
    height: 16px;
    fill: #fff;
    margin-right: 8px;
  }
}

.main-nav {
  @include response(1024px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    max-width: 380px;
    width: 100%;
    background: #c59292;
    height: 100vh;
    padding: 75px 50px 150px;
    transform: translateX(-100%);
    transition: .5s ease-in-out;
    z-index: 7;
    @include response(359px) {
      padding: 75px 50px 100px;
    }
    &.open {
      transform: translateX(0);
    }
  }
  &__button {
    display: none;
    cursor: pointer;
    svg {
      width: 15px;
      height: 15px;
      fill: #fff;
    }
    @include response(1024px) {
      display: block;
      margin-right: 50px;
    }
    @include response(767px) {
      margin-right: 0;
    }
  }
  &__close {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    svg {
      width: 15px;
      height: 15px;
      fill: #fff;
    }
    @include response(1024px) {
      display: block;
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    @include response(1024px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__item {
    position: relative;
    margin: 0 20px;
    font-size: 12px;
    text-transform: uppercase;
    @include response(1250px) {
      margin: 0 10px;
      font-size: 11px;
    }
    @include response(1024px) {
      font-size: 16px;
      margin: 0 0 30px;
    }
    &:first-of-type {
      margin-left: 0;
      margin-right: 40px;
      @include response(1250px) {
        margin-right: 10px;
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
    a {
      color: #fff;
      text-decoration: none;
      transition: .3s ease-in-out;
    }
    @include hover() {
      a {
        color: #fab8b3;
      }
    }
    &--online {
      &::before {
        content: '';
        left: -25px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        @extend %icon-menu-iconHover;
        transition: .3s ease-in-out;
      }
      &:hover {
        &::before {
          @extend %icon-menu-icon;
        }
      }
    }
  }
  .header-language {
    @include response(767px) {
      margin-bottom: 20px;
    }
  }
  .mobile-phone {
    @include response(767px) {
      display: flex;
      align-items: center;
      flex-direction: row;
      font-size: 20px;
      font-weight: 600;
      a {
        text-decoration: none;
        color: #fff;
      }
      svg {
        width: 22px;
        height: 25px;    
        fill: #fff;
        margin-right: 8px;
      }
    }
  }
}

.logo {
  margin-left: 25px;
  @include response(1450px) {
    margin: 0;
  }
  @include response(1024px) {
    margin: 0 20px;
  }
  @include response(767px) {
    margin: 0;
  }
}

.header-basket {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -26px;
    width: 1px;
    height: 23px;
    background: #5f565a;
    @include response(1250px) {
      display: none;
    }
  }
  &__button {
    cursor: pointer;
    svg {
      width: 17px;
      height: 17px;
      fill: #fff;
    }
    @include hover() {
      svg {
        fill: #fab8b3
      }
    }
  }
  &__count {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
  }
}
