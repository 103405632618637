.tab {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: .3s ease-in-out;
  &.active{
    height: auto;
    overflow: visible;
    opacity: 1;
  }
}