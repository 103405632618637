.services-content {
  text-align: center;
  padding-top: 50px;
  margin-bottom: 100px;
  @include response(767px) {
    padding-top: 25px;
    margin-bottom: 50px;
  }
  svg {
    width: 91px;
    height: 70px;
    @include response(767px) {
      width: 71px;
      height: 50px;
    }
  }
  &__text {
    max-width: 680px;
    width: 100%;
    margin: 0 auto;
    font-family: 'prt', sans-serif;
    font-size: 30px;
    color: $green;
    @include response(767px) {
      padding: 0 15px;
      font-size: 18px;
    }
  }
}

.service-block {
  margin-bottom: 18px;
  &__list {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .services-block__item {
      margin: 0 15px 30px;
      @include response(480px) {
        max-width: 100%;
        flex-basis: auto;
        margin: 0 0 30px;
      }
    }
  }
  &__item {
    position: relative;
    display: block;
    max-width: 370px;
    width: 100%;
    flex-basis: 370px;
    height: 370px;
    margin: 0 14px 28px;
    border-radius: 10px;
    overflow: hidden;
    transition: .3s ease-in-out;
    @include response(1250px) {
      max-width: 350px;
      flex-basis: 350px;
      height: 350px;
      margin: 0 15px 30px;
    }
    @include response(767px) {
      max-width: 290px;
      flex-basis: 290px;
      height: 290px;
    }
    @include hover() {
      .service-block__overlay {
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
      }
      .service-block__subtitle {
        visibility: visible;
        opacity: 1;
        overflow: visible;
      }
    }
  }
  &__title {
    text-align: center;
    width: 100%;
    font-family: 'prt', sans-serif;
    font-size: 36px;
    line-height: 1.2;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 15px;
    z-index: 2;
    transition: .3s ease-in-out;
    @include response(767px) {
      font-size: 24px;
    }
  }
  &__subtitle {
    padding: 5px 0 6px;
    width: 100%;
    font-family: 'ese', sans-serif;
    font-size: 30px;
    color: #000;
    text-align: center;
    background: #fff;
    position: absolute;
    bottom: 38px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 0 9px rgba(255, 255, 255, 0.5);
    transition: .4s ease-in-out;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
  }
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 169, 163, 0.5);
    visibility: visible;
    opacity: 1;
    overflow: visible;
    transition: .3s ease-in-out;
    &::before {
      content: '';
      border: 3px solid rgba(255, 255, 255, 0.21);
      position: absolute;
      top: 13px;
      left: 13px;
      right: 13px;
      bottom: 13px;
      border-radius: 10px;
      z-index: 2;
    }
  }
}

.bottom-content {
  margin-bottom: 70px;
  .services-block__image {
    text-align: center;
    margin-bottom: 22px;
  }
  &__text {
    max-width: 1010px;
    width: 100%;
    margin: 0 auto;
    @include response(1250px) {
      padding: 0 15px;
    }
  }
  &--custom {
    margin-left: 135px;
    @include response(1250px) {
      margin-left: 0;
    }
  }
}

.top-quote {
  background: #fbd4d4;
  padding: 50px 0 60px;
  @include response(767px) {
    padding: 50px 15px 60px;
  }
  &__inner {
    max-width: 1002px;
    width: 100%;
    margin: 0 auto;
    border: 10px solid #e8c8c7;
    padding: 52px 15px 60px;
    @include response(767px) {
      padding: 26px 15px 30px;
    }
  }
  &__text {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-family: 'prt', sans-serif;
    font-size: 30px;
    color: $green;
    @include response(767px) {
      font-size: 18px;
    }
  }
}

.service-page-content {
  text-align: center;
  padding-top: 70px;
  margin-bottom: 55px;
  .services-block__image {
    margin-bottom: 25px;
  }
  &__title {
    font-family: 'prt', sans-serif;
    font-size: 46px;
    color: $green;
    margin-bottom: 10px;
    @include response(991px) {
      font-size: 38px;
    }
    @include response(767px) {
      font-size: 26px;
    }
  }
  &__text {
    font-family: 'ese', sans-serif;
    font-size: 60px;
    color: $green;
    @include response(991px) {
      padding: 0 15px;
      font-size: 50px;
    }
    @include response(767px) {
      font-size: 36px;
      line-height: 32px;
    }
  }
}

.service-about {
  &__main-title {
    text-align: center;
    font-family: 'prt', sans-serif;
    font-size: 46px;
    color: $green;
    margin-bottom: 70px;
    @include response(991px) {
      font-size: 38px;
    }
    @include response(767px) {
      font-size: 26px;
    }
  }
  &__list {
    max-width: 700px;
    width: 100%;
    margin: 0 auto 75px;
    counter-reset: a;
    @include response(767px) {
      padding: 0 15px;
    }
  }
  &__item {
    position: relative;
    padding: 20px 35px 30px 48px;
    width: 100%;
    min-height: 171px;
    background: #ecdbd1;
    border: 1px solid #d2c6bc;
    border-radius: 5px;
    margin-bottom: 85px;
  }
  &__content {
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      left: -93px;
      top: -47px;
      width: 142px;
      height: 127px;
      background: #ecdbd1;
      z-index: -1;
      @include response(767px) {
        left: -50px;
      }
    }
    &::after {
      content: '1';
      content: counter(a);
      counter-increment: a 1;
      font-family: 'ese', sans-serif;
      font-size: 222px;
      color: rgba(68, 96, 85, 0.2);
      position: absolute;
      left: -100px;
      top: -125px;
      position: absolute;
      z-index: -1;
      @include response(991px) {
        left: -90px;
        top: -135px;
      }
      @include response(767px) {
        left: -65px;
      }
    }
  }
  &__title {
    font-size: 24px;
    color: $green;
    text-transform: uppercase;
    margin-bottom: 20px;
    z-index: 2;
    @include response(767px) {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
  }
}

.to-catalogue {
  position: relative;
  min-height: 304px;
  margin-bottom: 58px;
  background-size: cover;
  &__box {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__title {
    width: 100%;
    color: $main;
    font-family: 'ese', sans-serif;
    font-size: 60px;
    margin-bottom: 25px;
    @include response(991px) {
      font-size: 50px;
    }
  }
}

.alert-block {
  border-radius: 5px;
  max-width: 1002px;
  width: 100%;
  margin: 0 auto 50px;
  @include response(991px) {
    padding: 0 15px;
  }
  &__content {
    border: 1px solid $main;
    box-shadow: inset 0 0 0 10px #ecdbd1;
    padding: 36px;
    border-radius: 5px;
    background: #f2c9c2;
    text-align: center;
    font-family: 'prt', sans-serif;
    font-size: 21px;
    color: $green;
    @include response(991px) {
      font-size: 16px;
    }
  }
}
