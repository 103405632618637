@mixin font($fontname, $filename, $fontweight, $fontstyle) {
  @font-face {
    font-display: auto;
    font-family: $fontname;
    src: url(#{'../fonts/'+$filename+'.eot'});
    src:
      local('😥'),
      url(#{'../fonts/'+$filename+'.woff'}) format("woff"),
      url(#{'../fonts/'+$filename+'.ttf'}) format("truetype");
    font-weight: $fontweight;
    font-style: $fontstyle;
  }
}

@include font('prt', 'Prata-Regular', 400, normal);
@include font('ese', 'EseninscrptTwo', 400, normal);
@include font('gh', 'Geometria-Heavy', 900, normal);
@include font('gt', 'Geometria-Thin', 100, normal);
@include font('gl', 'Geometria-Light', 300, normal);