* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  margin: 0;
}
// input styles
input:not([type="checkbox"]):not([type="radio"]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}
// clear input button in ie
input::-ms-clear {
  display: none;
}
// stretching img relative to a parent block
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}
