
%icon-menu-icon {
  background-image: url(../images/sprite.png);
  background-position: 0px 0px;
  width: 13px;
  height: 9px;
}
%icon-menu-iconHover {
  background-image: url(../images/sprite.png);
  background-position: -18px 0px;
  width: 13px;
  height: 9px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  %icon-menu-icon {
    background-image: url(../images/sprite@2x.png);
    background-size: 31px 9px;
  }
  %icon-menu-iconHover {
    background-image: url(../images/sprite@2x.png);
    background-size: 31px 9px;
  }
}
