body {
  background: #fff;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  &.custom-bg,
  &.main {
    background: #ecdbd1;
  }
}
* {
  outline: none;
  box-sizing: border-box;
  &::after,
  &::before {
    box-sizing: border-box;
  }
}
svg {
  transform: translateZ(0);
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type="submit"] {
  cursor: pointer;
}
h1 {
  font-size: 34px;
  line-height: 36px;
  font-weight: 700;
}
h2 {
  font-size: 24px;
  line-height: 26px;
  font-weight: normal;
}
h3 {
  font-size: 22px;
  line-height: 24px;
  font-weight: normal;
}
h4 {
  font-size: 16px;
  line-height: 18px;
  font-weight: normal;
}
h5 {
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
}
h6 {
  font-size: 10px;
  line-height: 12px;
  font-weight: normal;
}
p.error {
  font-size: 12px;
  line-height: 14px;
  color: red;
  position: absolute;
  margin: 5px 5px 0;
  @include response(767px) {
    margin: 2px 5px 0;
  }
}
.wrapper {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  padding-bottom: 410px;
  @include response(991px) {
    padding-bottom: 395px;
  }
  @include response(767px) {
    padding-bottom: 520px;
  }
}
.holder-block {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.head-wrap {
  @include response(767px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 55px;
    .services-block__image,
    .portfolio-block__image,
    .instagram-block__image {
      margin: 0 10px 0 auto;
    }
    .main-title, {
      font-size: 32px;
      margin: 0;
      color: #fff;
      background: #AAAAAA;
      padding: 10px 30px 5px;
      height: auto;
      min-height: 68px;
      line-height: 27px;
      @include flexC();
      &::after {
        display: none;
      }
    }
  }
}

.main-title {
  text-align: center;
  position: relative;
  font-family: 'prt', sans-serif;
  font-size: 46px;
  color: $green;
  margin-bottom: 55px;
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -17px;
    width: 61px;
    height: 1px;
    background: $green;
  }
  @include response(767px) {
    font-size: 26px;
  }
}

.main-button {
  position: relative;
  max-width: 182px;
  width: 100%;
  margin: 0 auto;
  height: 48px;
  @include flexC();
  background: $main;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  border-radius: 24px;
  transition: .3s ease-in-out;
  @include response(767px) {
    font-size: 14px;
    height: 40px;
  }
  &::before {
    content: '';
    position: absolute;
    left: -6px;
    top: -6px;
    right: -6px;
    bottom: -6px;
    border-radius: 30px;
    border: 1px solid $main;
    transition: .3s ease-in-out;
  }
  @include hover() {
    background: #f8968f;
    &::before {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.slick-dots {
  li {
    button {
      background: #446055;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      opacity: 0.1;
      transition: .3s ease-in-out;
      &::before {
        display: none;
      }
    }
    &.slick-active {
      button {
        opacity: 1;
      }
    }
  }
}

.form-block {
  position: relative;
  max-width: 240px;
  width: 100%;
}

.main-input {
  font-size: 14px;
  border: 1px solid #e8e8e8;
  width: 100%;
  height: 48px;
  border-radius: 30px;
  padding: 0 26px;
  &::placeholder {
    color: #7f7f7f;
  }
}

input {
  &:focus,
  &:active {
    border-color: #333;
  }
}
textarea {
  &:focus,
  &:active {
    border-color: #333;
  }
}

.main-textarea {
  font-size: 14px;
  border: 1px solid #e8e8e8;
  width: 100%;
  height: 118px;
  border-radius: 30px;
  padding: 10px 26px;
  resize: none;
  font-family: 'Open Sans', sans-serif;
  &::placeholder {
    color: #7f7f7f;
  }
}

button {
  &.main-button {
    position: relative;
    cursor: pointer;
    border: none;
    display: block;
    text-align: center;
  }
}

.text {
  font-size: 15px;
  color: #000;
  line-height: 1.2;
  @include response(767px) {
    font-size: 16px;
  }
  p {
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  em {
    font-size: 21px;
    color: $green;
  }
}

.head-title {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto 50px;
  font-family: 'ese', sans-serif;
  font-size: 72px;
  color: $green;
  text-align: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background: rgba(111, 111, 111, 0.11);
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -7px;
    width: 100%;
    height: 1px;
    background: rgba(111, 111, 111, 0.11);
  }
  @include response(767px) {
    font-size: 46px;
    &::before {
      top: -3px;
    }
  }
}

.breadcrumb {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  max-width: 1170px;
  letter-spacing: -4px;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid #ceccc6;
  padding-bottom: 18px;
  padding-top: 20px;
  @include response(1199px) {
    padding: 20px 15px 18px;
  }
  @include response(767px) {
    display: none;
  }
  li {
    display: inline-block;
    vertical-align: top;
    letter-spacing: 0;
    position: relative;
    font-weight: 500;
    & + li {
      margin-left: 32px;
      &::after {
        content:'•';
        position: absolute;
        top: 0;
        left: -20px;
        font-size: 22px;
        color: $green;
      }
    }
  }
  a {
    color: #000;
    text-decoration: none;
    font-weight: 500;
    transition: .3s ease-in-out;
    span {
      color: #000;
      transition: .3s ease-in-out;
      @include hover() {
        color: $main;
        border-bottom: 1px dotted $main;
        transition: .3s ease-in-out;
      }
    }
  }
  span {
    color: rgba(0, 0, 0, 0.3);
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 20px 0 50px;
  li {
    margin: 0 10px;
    a,
    span {
      width: 36px;
      height: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-family: 'prt', sans-serif;
      font-size: 14px;
      border: 1px solid rgba(208, 208, 208, 0.2);
      border-radius: 50%;
      color: $green;
      font-weight: bold;
      @include response(500px) {
        display: none;
      }
    }
    a {
      transition: .4s ease-in-out;
      svg {
        fill: #a0a399;
        transition: .4s ease-in-out;
      }
      @include hover() {
        color: $main;
        transition: .4s ease-in-out;
        svg {
          fill: $main;
          transition: .4s ease-in-out;
        }
      }
    }
    span {
      border: 1px solid $main;
      background: $main;
      color: #fff;
      border: none;
      @include response(600px) {
        display: flex;
      }
    }
    &.first {
      transform: rotate(180deg);
      margin-right: 25px;
      a {
        @include response(600px) {
          display: flex;
        }
      }
    }
    &.last {
      margin-left: 25px;
      a {
        @include response(600px) {
          display: flex;
        }
      }
    }
    &.dots {
      position: relative;
      min-width: 36px;
      min-height: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-size: 14px;
      font-weight: bold;
      border-radius: 50%;
      &::before {
        content: '...';
        width: 16px;
        color: #b2b2b2;
        text-align: center;
      }
      @include response(600px) {
        display: flex;
      }
    }
    &:nth-child(2) {
      @include response(600px) {
        a {
          display: flex;
        }
      }
    }
    &:nth-last-child(2) {
      @include response(600px) {
        a {
          display: flex;
        }
      }
    }
    @include response(600px) {
      margin: 0 2px;
    }
  }
}

body.custom-bg {
 .breadcrumb {
   border: none;
 } 
}










.main {
  .loader {
    .image-box {
      display: none;
    }
  }
  .preloader {
    background: #000;
    &.done {
      transform: translateX(100%);
    }
  }
}

.loader {
  visibility: hidden;
  transition: .4s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;
  &.active {
    visibility: visible;
  }
  &.fixed {
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    animation: none;
  }
  .image-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.loader-borders {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 320px;
  height: 639px;
  .loader-border-1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background: #fff;
    transition: .5s ease-in-out;
    &.animate {
      width: 0;
    }
  }
  .loader-border-2 {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    height: 100%;
    width: 1px;
    transition: .5s ease-in-out;
    &.animate {
      height: 0;
    }
  }
  .loader-border-3 {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #fff;
    height: 100%;
    width: 1px;
    transition: .5s ease-in-out;
    &.animate {
      height: 0;
    }
  }
}

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  @include flexC();
  z-index: 9;
  background: #ecdbd1;
  transition: 0.6s ease-in-out;
  &.done {
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
  }
}

.main-section__image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  transition: 1.1s ease-in-out;

  z-index: 2;
  transform: translate(270%, 20%) scale(5.2);
  @include response(1450px) {
    transform: translate(220%, 20%) scale(4.5);
  }
  @include response(1300px) {
    transform: translate(180%, -30%) scale(4.0);
  }
  @include response(1250px) {
    transform: translate(120%, -50%) scale(2.8);
  }
  @include response(767px) {
    transform: translate(-45%, 50%) scale(1.6);
  }

  &.fixed {
    z-index: 2;
    transform: translate(270%, 20%) scale(5.2);
    @include response(1450px) {
      transform: translate(220%, 20%) scale(4.5);
    }
    @include response(1300px) {
      transform: translate(180%, -30%) scale(4.0);
    }
    @include response(1250px) {
      transform: translate(120%, -50%) scale(2.8);
    }
    @include response(767px) {
      transform: translate(-45%, 50%) scale(1.6);
    }
  }
  @media (orientation: landscape) and (max-width: 991px) {
    display: none;
  }
}

.image-box {
  &.animate {
    animation: movex 4s linear infinite;
    img {
      animation: movey 7s linear infinite;
    }
  }
}

.loader-decore {
  position: absolute;
  left: 50%;
  bottom: 60px;
  transform: translateX(-50%);
  @include response(767px) {
    bottom: 100px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  0% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  0% {
    transform: scale(1);
  }
}

@keyframes movex {
  0% {
    transform: translateX() 0;
  }
  50% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes movey {
  0% {
    transform: translateY() 0;
  }
  50% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0);
  }
}



.success.success_subscribe {
  position: absolute;
  margin: 12px 5px 0;
  font-size: 14px;
  @include response(767px) {
    position: static;
    text-align: center;
    padding: 0 15px;
  }
}

@include response(767px) {
  .subscribe-block {
    position: relative;
    .form-block {
      margin: 0 auto 25px !important;
    }
  }
  p.error.error_subscribe {
    bottom: 49px;
    left: 49px;
  }
}

// .mobile-fake {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: #000;
//   z-index: 100000;
//   color: #fff;
//   font-size: 18px;
//   line-height: 20px;
//   font-weight: 500;
//   text-transform: uppercase;
//   text-align: center;
//   display: none;
//   svg {
//     width: 60px;
//     height: 60px;
//     fill: #fff;
//   }
//   span {
//     display: inline-block;
//     vertical-align: middle;
//     position: relative;
//     padding: 25px 0 0;
//   }
//   @media (orientation: landscape) and (max-width: 768px) {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//   }
// }